import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Container } from "../../components/Container";
import { Button } from "../../ui/common/Button";
import { CharterOrganizationFilters } from "./components/CharterOrganizationFilters";
import { OrganizationItem } from "./components/OrganizationItem";
import { useLazyGetOrganizationsQuery } from "../../../services/rtkQuery/organization";
import { useAppSelector } from "../../../store/hooks";
import { selectOrganizationUsers } from "../../../store/modules/organization/selectors";
import { Organization } from "../../../store/modules/employee/types";
import Pagination from "../../ui/common/Pagination";
import { Option, SelectBox } from "../../ui/common/SelectBox";
import { numberPageAssigned, numberPageUser } from "../../../utill/contants";
import { CreateOrganizationModal } from "./components/CreateOrganizationModal";
import { useNavigate } from "react-router";
import { useCreateOrganization } from "../../../hooks/useCreateOrganization";

const Organizations = () => {
  const [fiscalYearEnd, setFiscalYearEnd] = useState<Date | "">("");
  const [seacrhQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numberPageAssigned[1]);
  const [employeeId, setEmployeeId] = useState({
    value: "",
    label: "All Carewolves",
  });
  const [selectedBorrower, setSelectedBorrower] = useState<Option | undefined>(
    undefined
  );
  const [isOpenOrganizationModal, setOpenOrganizationModal] = useState(false);

  const navigate = useNavigate();

  const organizationList = useAppSelector(selectOrganizationUsers);

  const [getOrganization] = useLazyGetOrganizationsQuery();

  const {
    control,
    handleSubmit,
    options,
    borrowerContactsOptions,
    setValue,
    errors,
    reset,
  } = useCreateOrganization(() => setOpenOrganizationModal(false));

  useEffect(() => {
    getOrganization({
      name: seacrhQuery,
      page: currentPage,
      limit: itemsPerPage.value,
      fiscalYearEnd: fiscalYearEnd,
      assigned_user_id: employeeId.value,
      borrower_userId: selectedBorrower?.value,
    });
  }, [
    seacrhQuery,
    currentPage,
    itemsPerPage,
    fiscalYearEnd,
    employeeId,
    selectedBorrower,
  ]);

  const goToDetailsScreen = (id: number) => {
    navigate(`/organization/${id}`);
  };

  const onChangeSearchQuery = (value: string | number) => {
    setSearchQuery(value.toString());
  };

  const openOrganizationModal = () => {
    setOpenOrganizationModal(true);
  };

  const closeOrganizationModal = () => {
    setOpenOrganizationModal(false);
  };

  const onBorrowerChange = (event: Option | Option[]) => {
    setSelectedBorrower(event as Option);
  };

  const startEntry =  organizationList && organizationList?.count > 0 ? (currentPage - 1) * +itemsPerPage.value + 1 : 0;
  const endEntry = organizationList && organizationList?.count > 0 ? Math.min(currentPage * +itemsPerPage.value, organizationList?.count) : 0;

  return (
    <div>
      <Container fullHeight={false}>
        <div className="h-full">
          <div className="h-[90%]">
            <div className="flex justify-between items-center w-full mb-[24px]">
              <div className="w-[90%]">
                <p className="text-[24px] text-[#474747] font-bold">
                  School Partner List
                </p>
                <p className="text-[14px] text-[#717984] folt-medium">
                  Manage School Partner Organizations
                </p>
              </div>
              <div className="w-[200px] text-[15.5px]">
                <Button
                  text="+ New School Partner"
                  onClick={openOrganizationModal}
                />
              </div>
            </div>
            <div className="mb-[24px]">
              <CharterOrganizationFilters
                value={seacrhQuery}
                setValue={onChangeSearchQuery}
                fiscalYearEnd={fiscalYearEnd}
                setFiscalYearEnd={setFiscalYearEnd}
                employeeId={employeeId}
                setEmployeeId={setEmployeeId}
                onBorrowerChange={onBorrowerChange}
                selectedBorrower={selectedBorrower}
              />
            </div>
            <div className="flex flex-col gap-3">
              {organizationList &&
                organizationList.rows.map((row: Organization) => (
                  <OrganizationItem
                    row={row}
                    goToDetailsScreen={goToDetailsScreen}
                  />
                ))}
            </div>
          </div>
          <div className="flex justify-between items-center h-[10%] mt-[30px]">
            <div className="w-[70%] justify-start">
              <Pagination
                itemsPerPage={+itemsPerPage.value}
                userCount={organizationList?.count ?? 1}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
            <div className="w-[30%] flex items-center justify-end gap-[16px]">
              <p className="text-[13px] text-[#717984]">
                Showing {startEntry} to {endEntry} of {organizationList?.count ?? 0} entries
              </p>
              <div className="flex h-[32px] p-[10px] justify-center items-center gap-[10px]">
                <SelectBox
                  value={itemsPerPage}
                  onChange={(value: Option | Option[]) =>
                    setItemsPerPage(value as Option)
                  }
                  options={numberPageUser}
                  placeholder="Show 8"
                  multiple={false}
                  placement="top"
                  searchEnabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
      {isOpenOrganizationModal && (
        <CreateOrganizationModal
          isOpenModal={isOpenOrganizationModal}
          closeModal={closeOrganizationModal}
          control={control}
          handleSubmit={handleSubmit}
          setValue={setValue}
          borrowerContactsOptions={borrowerContactsOptions}
          options={options}
          errors={errors}
          reset={reset}
        />
      )}
    </div>
  );
};

export default Organizations;
