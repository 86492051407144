import React, { useEffect, useState } from "react";
import { Container } from "../../components/Container";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CroupLeftIcon from "../../assets/icons/GroupLeftIcon";
import { OrganizationInfo } from "./components/OrganizationInfo";
import {
  useDeleteOrganizationMutation,
  useLazyGetOrganizationsByIdQuery,
} from "../../../services/rtkQuery/organization";
import { useAppSelector } from "../../../store/hooks";
import { selectOrganizationById } from "../../../store/modules/organization/selectors";
import { DocumentsOrganizationFilters } from "./components/DocumentsOrganizationFilters";
import { OrganizationTable } from "./components/DocumentsTable";
import { DocumentsTableBody } from "./components/DocumentsTableBody";
import { DocumentTableHead } from "./components/DocumentsTableHeader";
import { useLazyGetOrganizationsDocumentsQuery } from "../../../services/rtkQuery/documents";
import { numberPageUser } from "../../../utill/contants";
import Pagination from "../../ui/common/Pagination";
import { selectOrganizationDocuments } from "../../../store/modules/documents/selectors";
import { Option, SelectBox } from "../../ui/common/SelectBox";
import { useEditOrganization } from "../../../hooks/useEditOrganization";
import { CreateOrganizationModal } from "../Organization/components/CreateOrganizationModal";

const OrganizationDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(numberPageUser[1]);
  const [name, setName] = useState("");
  const [editOrganizationOpen, setEditOrganizationOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "",
  });
  const params = useParams();
  const { id } = params;
  const organization = useAppSelector(selectOrganizationById);
  const organizationDocuments = useAppSelector(selectOrganizationDocuments);
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    options,
    borrowerContactsOptions,
    setValue,
    watch,
    reset,
  } = useEditOrganization(id, () => setEditOrganizationOpen(false));

  const onDelete = () => {
    deleteOrganization(organization?.id).then(()=>{
      navigate('/organization')
    });
  };
  const [getOrganizationById] = useLazyGetOrganizationsByIdQuery();

  useEffect(() => {
    getOrganizationById({ id });
  }, [id]);

  const [getOrganizationDocuments] = useLazyGetOrganizationsDocumentsQuery();

  useEffect(() => {
    getOrganizationDocuments({
      id,
      page: currentPage,
      limit: +itemsPerPage.value,
      title: name,
      categoryId:
        selectedCategory.value.length > 0 ? +selectedCategory.value : undefined,
    });
  }, [currentPage, itemsPerPage, name, selectedCategory]);

  const onChangeSelectedValue = (e: Option | Option[]) => {
    if (Array.isArray(e)) {
      return;
    }
    setSelectedCategory(e);
  };

  return (
    <div>
      <Container>
        <div>
          <Link
            to="/organization"
            className="items-center flex text-[14px] text-[#000] font-bold"
          >
            <div className="flex items-center">
              <CroupLeftIcon />
            </div>
            Back to Organizations
          </Link>
          <div className="mb-[24px]">
            <OrganizationInfo
              organizationId={id}
              organization={organization}
              onDelete={onDelete}
              openEdit={() => setEditOrganizationOpen(true)}
              totalDocuments={organizationDocuments?.rows?.length}
            />
          </div>
          <div>
            <DocumentsOrganizationFilters
              name={name}
              setName={setName}
              selectedCategory={selectedCategory}
              onChangeSelectedValue={onChangeSelectedValue}
            />
          </div>
          <div>
            <OrganizationTable
              BodyComponent={DocumentsTableBody}
              HeaderComponent={DocumentTableHead}
              documentsList={organizationDocuments?.rows}
            />
          </div>
          <div className="flex justify-between">
            <div className="w-[70%] justify-start">
              <Pagination
                itemsPerPage={+itemsPerPage.value}
                userCount={organizationDocuments?.count}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
            <div className="w-[30%] flex items-center justify-end gap-[16px]">
              <p className="text-[13px] text-[#717984]">
                Showing { organizationDocuments && organizationDocuments?.count > 0 ? (currentPage - 1) * +itemsPerPage.value + 1 : 0} to {Math.min(currentPage * +itemsPerPage.value, organizationDocuments?.count || 0)} of {organizationDocuments?.count || 0} entries
              </p>
              <div className="flex h-[32px] p-[10px] justify-center items-center gap-[10px]">
                <SelectBox
                  value={itemsPerPage}
                  onChange={(value: Option | Option[]) =>
                    setItemsPerPage(value as Option)
                  }
                  options={numberPageUser}
                  placeholder="Show 8"
                  multiple={false}
                  searchEnabled={false}
                />
              </div>
            </div>
          </div>
          {editOrganizationOpen && (
            <CreateOrganizationModal
              isOpenModal={editOrganizationOpen}
              closeModal={() => setEditOrganizationOpen(false)}
              control={control}
              handleSubmit={handleSubmit}
              setValue={setValue}
              borrowerContactsOptions={borrowerContactsOptions}
              options={options}
              watch={watch}
              action="edit"
              reset={reset}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default OrganizationDetails;
