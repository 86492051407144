import React, { FC } from "react";
import { SearchInput } from "../../../components/SearchInput";
import { Option, SelectBox } from "../../../ui/common/SelectBox";
import { lenderUserStatus } from "../../../../utill/contants";
import { roles } from "../../Employee/components/CreateEmployeeModal";
import { useAppSelector } from "../../../../store/hooks";
import { selectUser } from "../../../../store/modules/user/selectors";
 interface BorrowerFiltersProps {
  fiscalYearEnd: Date | "";
  setFiscalYearEnd: (date: any) => void;
  value: string;
  setValue: (value: string | number) => void;
  onChangeStatus: (value: Option | Option[]) => void;
  onChangeRole: (value: Option | Option[]) => void;
  role: Option | undefined;
  status: Option | any;
  organizationId: Option | undefined;
  onChangeOrganization: (value: Option | Option[]) => void;
  organizationOptions: Option[];
}

export const BorrowerFilters: FC<BorrowerFiltersProps> = ({
  fiscalYearEnd,
  setFiscalYearEnd,
  value,
  setValue,
  onChangeStatus,
  onChangeRole,
  role,
  status,
  organizationId,
  onChangeOrganization,
  organizationOptions,
}) => {
  const user = useAppSelector(selectUser);

  return (
    <div>
      <div className="flex justify-between items-center gap-[16px]">
        <div className="w-[40%]">
          <SearchInput
            value={value}
            placeholder="Search School Partner Contacts"
            type="text"
            onChange={setValue}
            icon={false}
          />
        </div>
        <div className="w-[60%] flex justify-between gap-[16px]">
          <SelectBox
            value={role}
            options={roles}
            onChange={onChangeRole}
            placeholder="All Roles"
            multiple={false}
            searchEnabled={false}
          />

          {(user?.type !== 'borrower_admin'  && user?.type !== 'borrower_user' ) && (
            <SelectBox
              placeholder="Select School partner"
              value={organizationId}
              onChange={onChangeOrganization}
              options={[
                { label: "All School partners", value: "" },
                ...organizationOptions,
              ]}
              multiple={false}
              searchEnabled={true}
            />
          )}

          <SelectBox
            value={status}
            options={lenderUserStatus}
            onChange={onChangeStatus}
            placeholder="All Statuses"
            multiple={false}
            searchEnabled={false}
          />
        </div>
      </div>
    </div>
  );
};
