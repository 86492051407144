import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { Option } from "../view/ui/common/SelectBox";
import { roles } from "../view/pages/Employee/components/CreateEmployeeModal";

import { useEditBorrowerMutation } from "../services/rtkQuery/borrower";
import {
  getEmailSchema,
  getUserRolesSchema,
} from "../utill/validation/validationSchemas";
import { useGetOrganizationsQuery } from "../services/rtkQuery/organization";
import { useAppSelector } from "../store/hooks";
import { selectOrganizationUsers } from "../store/modules/organization/selectors";
import { getOptions } from "../utill/helpers";

const borrowerUserSchema = Yup.object().shape({
  fullName: Yup.string().required("Please enter full name"),
  email: getEmailSchema(),
  password: Yup.string().min(6, "Please enter at least 6 characters"),
  role: getUserRolesSchema(),
  organization: Yup.object({
    value: Yup.string().required(),
    label: Yup.string().required(),
  }),
});

export interface EditedBorrowerData {
  fullName: string;
  email: string;
  password?: string;
  role: Option;
  organization: Option;
}
export const useEditBorrowerUser = (
  borrowerUser: any,
  onSuccess: () => void
) => {
  let defaultBorrowerValues = useRef<EditedBorrowerData>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      role: { value: "", label: "" },
      organization: { value: "", label: "" },
    },
    resolver: yupResolver(borrowerUserSchema),
  });

  const [editBorrower, { isSuccess }] = useEditBorrowerMutation();

  useGetOrganizationsQuery({getAll: true});

  const organizationList = useAppSelector(selectOrganizationUsers);

  const organizationOptions = useMemo(() => {
    if (organizationList && organizationList.rows) {
      return getOptions(organizationList?.rows, "name", "id");
    }
    return [];
  }, [organizationList?.rows]);

  useEffect(() => {
    if (borrowerUser && organizationOptions.length) {
      reset({
        fullName: borrowerUser.fullName,
        role: roles.find((el: Option) => el.value === borrowerUser.role),
        email: borrowerUser.email,
        organization: organizationOptions.find(
          (item) => String(item.value) === String(borrowerUser.organizationId)
        ) ?? { value: "", label: "" },
      });
    }
    defaultBorrowerValues.current = getValues() as EditedBorrowerData;
  }, [borrowerUser]);

  const onSubmit = (data: EditedBorrowerData) => {
    const isChanged =
      JSON.stringify(defaultBorrowerValues.current) !==
      JSON.stringify(getValues());

    if (isChanged) {
      const payload: {
        fullName: string;
        role: string;
        email: string;
        password?: string;
      } = {
        fullName: data.fullName,
        role: data.role.value,
        email: data.email,
      };
      if (data.password) {
        payload.password = data.password;
      }

      editBorrower({
        id: borrowerUser.id,
        data: { ...payload, organizationId: data.organization.value },
      }).then(() => {
        onSuccess();

        if (isSuccess) {
          toast.success("School partner contact was updated");
        }
      });
    } else {
      toast.info("You didn't change any fields");
    }
  };

  return {
    control,
    organizationOptions,
    handleSubmit: handleSubmit(onSubmit),
    errors,
  };
};
